<template>

<div class="Isolation">
<barre-navigation></barre-navigation>

    <div class="specialiteIsolation">
        <!-- <div class="lignenav"></div> -->
        <div class="blocIsolation">
            <div class="centrerIsolation">
                <div class="titreIsolation">
                    <h1> Isolation placo </h1>
                    <div class="ligneIsolation"></div>
                </div>
                <div class="descriptionIsolation">
                    <p> Nous réalisons l’étanchéité à l’air aux normes RT 2012 sur <span> chaque type de support </span> (maisons BBC). Une étanchéité à l’air performante est indispensable pour un <span> bâti économe en énergie </span> et un <span> confort de vie optimal </span> été comme hiver.
                    Isolants utilisés : laine minérale comme la laine de verre ou la laine de roche, fibre de bois, granules ragréage Fermacell, isolant polystyrène… <br><br>
                    Le pare vapeur est une membrane posée en complément, afin de <span> faire barrière à la vapeur d’eau </span> qui provient de nos activités quotidiennes et de <span> protéger à la fois l’isolant et la structure du bâtiment </span>. Cet écran de protection contribue également à maintenir une qualité thermique idéale. 
                    </p>
                </div>
            </div>
            <div class="imgIsolation">
                <img alt="image-isolation" src="../assets/isolation1.png">
            </div>
            <div class="imgIsolation2">
                    <img alt="image-pareVapeur" src="../assets/pare-vapeur.png">
            </div>
                <div class="descriptionIsolation2">
                    <p>
                        Les plaques de plâtre sont utilisées dans la majorité des logements pour <span> cloisonner ou isoler les pièces </span>. Le choix des plaques de plâtre est fonction du type de travaux à réaliser : standards dans les pièces dites sèches,  hydrofuges pour les pièces humides, coupe-feu pour les cloisons soumises à de fortes températures ou encore phoniques pour  isoler du bruit.
                    </p>
                </div>
                <div class="bonusIsolation">
                    <div class="avapIsolation">
                        <cartes-avant-apres imgav="isolation-av.png" imgap="isolation-ap.png"></cartes-avant-apres>
                    </div>
                    <img alt="image-isolation-plaquedeplatre" src="../assets/isolation2.png">
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import BarreNavigation from '../components/BarreNavigation.vue'
    import CartesAvantApres from '../componentschild/CartesAvantApres.vue'

    export default {
        name: 'Isolation',
        components: {
        BarreNavigation, CartesAvantApres
        }
    }

</script>

<style scoped>

    .lignenav {

        width: 1520px;
        height: 1px;
        background-color: #EFF0F6;
        margin: auto;

    }

    .specialiteIsolation {

        padding-right: 14rem;
        padding-left: 14rem;

    }

    .blocIsolation {

        padding: var(--padding-haut-bas-section);

    }

    .blocIsolation h1 {

        font-size: 36px;
        font-weight: bold;
        color: #333F50;
        padding-bottom: 40px;

    }

    .blocIsolation p {

        font-size: 16px;
        color: #333F50;

    }

    .centrerIsolation {

        display: flex;
        justify-content: space-evenly;

    }

    .ligneIsolation {

        background-color: #7C928F;
        height: 10px;
        width: 400px;

    }

    .descriptionIsolation {

        margin-left: 50px;
        font-size: 20px;

    }

    .descriptionIsolation p {

        line-height: var(--line-height-pagesdetails);

    }

    .descriptionIsolation span {

        font-weight: bold;

    }

    .imgIsolation {

        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 44px;

    }

    .imgIsolation img {

        height: 475px;
        width: 815px;
        align-self: center;

    }

    .imgIsolation2 {

        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 120px;

    }

    .imgIsolation2 img {

        height: 611px;
        width: 815px;
        align-self: center;

    }

    .descriptionIsolation2 {

        padding-top: 12rem;
        padding-bottom: 10rem;

    }

    .descriptionIsolation2 p {

        padding-right: 14rem;
        padding-left: 14rem;
        line-height: var(--line-height-pagesdetails);

    }

    .bonusIsolation {

        display: flex;
        justify-content: space-evenly;
        align-items: center;

    }

    .avapIsolation {

        height: 426px;

    }

    @media screen and (max-width: 1650px) {

        .imgIsolation img {

            height: 55%;
            width: 55%;

        }

        .imgIsolation2 img {

            height: 55%;
            width: 55%;

        }

    }

    @media screen and (max-width: 1500px) {

        .centrerIsolation {

            padding-bottom: 10px;

        }

    }

    @media screen and (max-width: 1400px) {

        .descriptionIsolation p {

            line-height: var(--line-height-medium);

        }

        .descriptionIsolation2 p {

            line-height: var(--line-height-medium);

        }

    }


    @media screen and (max-width: 1300px) {

        .ligneIsolation {
            
            width: 300px;

        }


        .imgIsolation img {

            height: 64%;
            width: 64%;

        }

        .imgIsolation2 img {

            height: 64%;
            width: 64%;

        }

    }

    @media screen and (max-width: 1110px) {

        .centrerIsolation {

            flex-direction: column;
            align-items: center;
            padding-bottom: 30px;

        }

        .titreIsolation {

            text-align: center;

        }

        .titreIsolation h1 {

            text-align: center;

        }

        .ligneIsolation {

            width: 500px;
            height: 8px;

        }

        .descriptionIsolation {

            padding-top: 30px;
            display: flex;
            justify-content: center;
            margin-left: 0px;

        }

        .descriptionIsolation p {

            width: 500px;
            text-align: justify;

        }

        .imgIsolation  {

            padding-top: 10px;

        }

        .imgIsolation img {

            height: 294px;
            width: 504px;

        }

        .imgIsolation2 {

            padding-top: 85px;

        }

        .imgIsolation2 img {

            height: 378px;
            width: 504px;

        }

        .descriptionIsolation2 {

            display: flex;
            justify-content: center;
            margin-left: 0px;

        }

        .descriptionIsolation2 p {

            text-align: justify;
            padding-right: 0rem;
            padding-left: 0rem;
            width: 500px;

        }

        .blocavantapres {

            display: none;

        }

        .bonusIsolation {

            justify-content: center;

        }

    }

    @media screen and (max-width: 1000px) {

        .centrerIsolation {

            padding-bottom: 50px;

        }

    }

    @media screen and (max-width: 750px) {

        .specialiteIsolation {

            padding-right: 6rem;
            padding-left: 6rem;
            
        }

        .bonusIsolation img {

            height: 372px;
            width: 279px;

        }

    }

    @media screen and (min-width: 670px) and (max-width: 870px) {

        .blocIsolation {

            padding-top: 60px;

        }

    }

    @media screen and (max-width: 650px) {

        .imgIsolation img {

            height: 266px;
            width: 456px;

        }

        .imgIsolation2 img {

            height: 342px;
            width: 456px;

        }

    }

    @media screen and (max-width: 550px) {

        .specialiteIsolation {

            padding-right: 0rem;
            padding-left: 0rem;

        }

        .titreIsolation h1 {

            font-size: 28px;
            text-align: center;
            padding-bottom: 30px;

        }

        .ligneIsolation {

            width: 300px;
            height: 6px;

        }

        .descriptionIsolation p {

            max-width: 300px;
            font-size: 14px;

        }

        .imgIsolation img {

            height: 175px;
            width: 300px;

        }

        .imgIsolation2 img {

            height: 225px;
            width: 300px;

        }

        .descriptionIsolation2 {

            padding-bottom: 6px;

        }


        .descriptionIsolation2 p {

            width: 300px;
            font-size: 14px;

        }

        .bonusIsolation img {

            height: 324px;
            width: 243px;

        }

    }

</style>
